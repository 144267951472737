import { FC } from 'react'
import SanityBlockContent, {
  BlockContentProps as SanityBlockContentProps,
} from '@sanity/block-content-to-react'
import { Link } from '../Link/Link'
import { RichTextWrap } from './BlockContentWrap'

export type BlockContentProps = {
  body: SanityBlockContentProps['blocks']
  additionalSerializers?: SanityBlockContentProps['serializers']
  className?: string
  theme?: 'default' | 'editorialContent' | 'slim'
}

const bold: FC = (props) => {
  return <strong>{props.children}</strong>
}

const italic: FC = (props) => {
  return <em>{props.children}</em>
}

const link: FC<{ mark: { link: string } }> = (props) => {
  return <Link {...props.mark}>{props.children}</Link>
}

export const BlockContent: FC<BlockContentProps> = ({
  body,
  className,
  additionalSerializers,
  theme = 'default',
}) => {
  const serializers = {
    ...additionalSerializers,
    marks: {
      bold,
      italic,
      link,
      ...(additionalSerializers?.marks ? additionalSerializers.marks : {}),
    },
  }

  return (
    <RichTextWrap theme={theme}>
      <SanityBlockContent
        className={className}
        blocks={body}
        serializers={serializers}
      />
    </RichTextWrap>
  )
}
