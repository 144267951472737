import { FC } from 'react'
import { styled } from '@magal/styles'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import 'swiper/css/autoplay'
import { Returns, Free, Warranty } from '@magal/icons'
import { Media } from '@magal/components'
import { MediaFieldType } from '@magal/models'

const ShippingArea = styled('div', {
  paddingBottom: '$16',
})

const ShippingIcons = styled('div', {
  marginRight: '7px',
  display: 'block',
  width: '22px',
})

const ShippingText = styled('div', {
  projectFont: 'body02',
  fontWeight: 'bold',
  textAlign: 'center',
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  variants: {
    list: {
      true: {
        display: 'flex',
        gap: '$4',
        padding: '$8',
        backgroundColor: '$gray400',
        borderRadius: '$r2',
        marginBottom: '$8',
      },
    },
  },
})

const SliderWrapper = styled('div', {
  width: '100%',
  maxWidth: 'calc(100vw - 32px)',
  padding: '0 0 $8',
  boxSizing: 'content-box',
  overflowX: 'scroll',
  '@lg': { maxWidth: '30vw' },
})

// const items = [
//   {
//     icon: Returns,
//     text: '90 Days Free Returns & Exchanges',
//   },
//   {
//     icon: Free,
//     text: 'Free Shipping',
//   },
//   {
//     icon: Warranty,
//     text: '2 Years Warranty',
//   },
// ]

export const Reassurance: FC<{
  items: { title: string; icon: MediaFieldType }[]
  list?: boolean | null
}> = ({ list = false, items = [] }) => {
  return (
    <ShippingArea>
      {list ? (
        items.map((item, index) => (
          <ShippingText list={list} key={index}>
            <ShippingIcons>
              <Media {...item.icon} sizes={'25px'} />
            </ShippingIcons>
            {item.title}
          </ShippingText>
        ))
      ) : (
        <SliderWrapper>
          <Swiper
            modules={[Autoplay]}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            slidesPerView={1}
          >
            <SwiperSlide>
              <ShippingText>
                <ShippingIcons>
                  <Returns />
                </ShippingIcons>
                90 Days Free Returns & Exchanges
              </ShippingText>
            </SwiperSlide>
            <SwiperSlide>
              <ShippingText>
                <ShippingIcons>
                  <Free />
                </ShippingIcons>
                Free Shipping
              </ShippingText>
            </SwiperSlide>
            <SwiperSlide>
              <ShippingText>
                <ShippingIcons>
                  <Warranty />
                </ShippingIcons>
                2 Years Warranty
              </ShippingText>
            </SwiperSlide>
          </Swiper>
        </SliderWrapper>
      )}
    </ShippingArea>
  )
}
