import { FC, ReactComponentElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from '@magal/components'
import { styled } from '@magal/styles'
import { Close } from '@magal/icons'
import { Reassurance } from './Reassurance'

const Root = styled('div', {
  '@lg': {
    display: 'none',
  },
})

const ModalRoot = styled('div', {
  color: '$green_09',
  display: 'grid',
  minHeight: '100%',
  paddingBottom: '$64',
})
const Heading = styled('h1', {
  position: 'sticky',
  top: 0,
  padding: '$containerMarginMobile',
  gridArea: '1/1',
  projectFont: 'caps02',
  placeSelf: 'start',
  zIndex: 1,
  pointerEvents: 'none',
})
const CloseModalButton = styled(Button, {
  position: 'fixed',
  top: '0',
  right: '0',
  width: '$headerHeightMobile',
  height: '$headerHeightMobile',
  color: '$green_09',
  zIndex: 1,
})

const Body = styled('div', {
  gridArea: '1/1',
  display: 'grid',
  gridGap: '$8',
  gridTemplateRows: 'auto 1fr auto auto',
  padding: '0 $containerMarginMobile',
})
const GalleryWrap = styled('div', {
  alignSelf: 'start',
  minHeight: '100px',
  margin: '0 -$containerMarginMobile',
})
const WizardWrap = styled('div', {
  alignSelf: 'start',
})
const AddToCartWrap = styled('div', {
  paddingBottom: '$containerMarginMobile',
})
export const PersonalizeModal: FC<{
  wizard: ReactComponentElement<any>
  addToCart: ReactComponentElement<any>
  gallery: ReactComponentElement<any>
  packaging?: ReactComponentElement<any> | null
  sizeGuide: ReactComponentElement<any> | null
  klarna: ReactNode
  onRequestOpen: () => void
  onRequestClose: () => void
  isOpen: boolean
  disabled: boolean
  reassuranceItems: { title: string; icon: any }[]
}> = ({
  wizard,
  onRequestClose,
  onRequestOpen,
  addToCart,
  gallery,
  sizeGuide,
  packaging,
  klarna,
  isOpen,
  disabled,
  reassuranceItems,
}) => {
  const { t } = useTranslation('sectionProductConversionArea')

  return (
    <Root>
      <Button
        appearance={'solidRed'}
        onClick={onRequestOpen}
        disabled={disabled}
      >
        {disabled ? t('outOfStock') : t('personalize')}
      </Button>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        size={'stretch'}
        transition={'slide-from-right'}
        preventScroll={true}
      >
        <ModalRoot>
          <CloseModalButton onClick={onRequestClose}>
            <Close />
          </CloseModalButton>
          <Heading>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personalizeBreak'),
              }}
            />
          </Heading>
          <Body>
            <GalleryWrap>{gallery}</GalleryWrap>
            <WizardWrap>{wizard}</WizardWrap>
            {sizeGuide}
            {packaging}
            {klarna}
            <AddToCartWrap>{addToCart}</AddToCartWrap>
            <Reassurance items={reassuranceItems} />
          </Body>
        </ModalRoot>
      </Modal>
    </Root>
  )
}
