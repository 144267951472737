import { klaviyoPopUp } from './klaviyoTag'

export const handlePopUpTrigger = (
  locale: string | undefined,
  ids?: number[],
) => {
  if (!locale) return

  if (locale === 'fr' || locale === 'fr-fr') {
    klaviyoPopUp('V4gQeR')

    return
  }

  if (locale === 'de') {
    klaviyoPopUp('RADFmZ')

    return
  }

  if (locale === 'en' || locale === 'us') {
    klaviyoPopUp('QQtscA')

    return
  }

  let attentiveMobile = 1010983
  let attentiveDesktop = 998615

  if (ids && ids.length > 0) {
    attentiveMobile = ids[0]
    attentiveDesktop = ids[1]
  }

  if (window.__attentive && window.__attentive.trigger) {
    if (window.innerWidth >= 760) {
      //call the desktop creative
      window.__attentive.trigger(null, null, null, attentiveDesktop)
    } else {
      //call the mobile creative
      window.__attentive.trigger(null, null, null, attentiveMobile)
    }
  }
}
