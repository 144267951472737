import { BlockContent, Media, RichTextWrap } from '@magal/components'
import { MediaFieldType, PortableText } from '@magal/models'
import { styled } from '@magal/styles'
import { FC } from 'react'

import { ProductAccordion } from '../ProductAccordion/ProductAccordion'

const MaterialsWrapper = styled('div', {
  display: 'grid',
  gap: '$12',
  marginBottom: '$20',
})

const MaterialBox = styled('div', {
  display: 'grid',
  gridTemplateColumns: '0.6fr 2fr',
  backgroundColor: '$gray400',
  borderRadius: '$r2',
  padding: '$8 $8 $8 0',
})

const MaterialBoxIcon = styled('div', {
  padding: '15%',
})

const MaterialDescription = styled('div', {
  display: 'block',
})

const MaterialLabel = styled('p', {
  projectFont: 'caps07',
  marginBottom: '$8',
})

export type ProductMaterialProps = {
  label: string
  description: PortableText
  image: MediaFieldType
}

export type AccordionProductDetailsProps = {
  title?: string
  materials?: ProductMaterialProps[] | null
  descriptionHTML?: string
}

export const AccordionProductDetails: FC<AccordionProductDetailsProps> = ({
  title,
  materials,
  descriptionHTML,
}) => {
  if (!(materials || descriptionHTML)) return null

  return (
    <ProductAccordion title={title} initiallyOpen={true}>
      {materials && materials.length > 0 && (
        <MaterialsWrapper>
          {materials.map((material) => (
            <MaterialBox key={material.label}>
              <MaterialBoxIcon>
                <Media {...material.image} sizes="180px" />
              </MaterialBoxIcon>
              <MaterialDescription>
                <MaterialLabel>{material.label}</MaterialLabel>
                <BlockContent body={material.description} theme={'slim'} />
              </MaterialDescription>
            </MaterialBox>
          ))}
        </MaterialsWrapper>
      )}
      {descriptionHTML && (
        <RichTextWrap>
          <div
            dangerouslySetInnerHTML={{
              __html: descriptionHTML,
            }}
          />
        </RichTextWrap>
      )}
    </ProductAccordion>
  )
}
