import { FC } from 'react'
import { styled } from '@magal/styles'
import { Button } from '@magal/components'
import { Star } from '@magal/icons'
import { useTranslation } from 'react-i18next'

const Root = styled(Button, {
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateRows: '1fr',
  gridTemplateColumns: 'min-content min-content',
  gap: '$12',
  alignItems: 'center',
})

const Stars = styled('div', {
  display: 'grid',
})

const StarsBucket = styled('div', {
  gridArea: '1/1',
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: 'repeat(5, min-content)',
  gap: '$2',
  overflow: 'hidden',
})

const VisibilityIndicator = styled('div', {
  gridArea: '1/1',
  width: '50%',
})

const StarsLabel = styled('span', {
  projectFont: 'body04',
  fontSize: '16px',
  textDecoration: 'underline',
  color: '$green_09',
  whiteSpace: 'nowrap',
})

type ProductReviewsProps = {
  reviewCount: number
  reviewAverageValue: string
}

export const ProductReviews: FC<ProductReviewsProps> = ({
  reviewCount,
  reviewAverageValue,
}) => {
  const { t } = useTranslation('sectionProductConversionArea')

  const handleClick = () => {
    //TODO: think of a better way to connect SectionOkendoReviews with this component
    const reviewsContainer = document.querySelector('#magal-reviews-container')

    if (reviewsContainer) {
      window.scrollBy({
        left: 0,
        top: reviewsContainer.getBoundingClientRect().top,
        behavior: 'smooth',
      })
    }
  }

  const avarageReviewPercentValue = (parseFloat(reviewAverageValue) * 100) / 5

  return (
    <Root onClick={handleClick}>
      <Stars>
        <StarsBucket
          css={{
            opacity: 0,
          }}
        >
          <Star fillColor={'green'} size={'small'} />
          <Star fillColor={'green'} size={'small'} />
          <Star fillColor={'green'} size={'small'} />
          <Star fillColor={'green'} size={'small'} />
          <Star fillColor={'green'} size={'small'} />
        </StarsBucket>

        <VisibilityIndicator
          css={{
            width: `${avarageReviewPercentValue}%`,
          }}
        >
          <StarsBucket>
            <Star fillColor={'green'} size={'small'} />
            <Star fillColor={'green'} size={'small'} />
            <Star fillColor={'green'} size={'small'} />
            <Star fillColor={'green'} size={'small'} />
            <Star fillColor={'green'} size={'small'} />
          </StarsBucket>
        </VisibilityIndicator>
      </Stars>
      <StarsLabel>{`${reviewCount} ${t('reviews')}`}</StarsLabel>
    </Root>
  )
}
