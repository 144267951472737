import { styled } from '@magal/styles'
import { LogoLoading } from '@magal/icons'
import { FC, useEffect, useState } from 'react'
import { getShopifyProductExtended } from '@magal/services/shopify-service'
import {
  SectionProductConversionAreaProps,
  AdvancedProductOption,
} from '@magal/models'
import { useRouter } from 'next/router'
import { captureException } from '@sentry/nextjs'
import { PriceRange } from '@magal/components'
import { LetterPopup } from './LetterPopup'
import { useTranslation } from 'react-i18next'

const Root = styled('section', {
  backgroundColor: '$white',
  borderRadius: '$r2',
  minWidth: '100%',
  padding: '30px 15px',
  position: 'relative',
  '@lg': {
    backgroundColor: '$gray300',
    marginTop: '0',
    padding: '10px 30px 25px',
  },
})

const Picture = styled('picture', {
  width: '80px',
  maxWidth: '98%',
  height: '80px',
  display: 'inline-block',
  border: '1px solid black',
  overflow: 'hidden',
  marginBottom: 10,
  '& img': {
    backgroundColor: '$gray300',
    height: '100%',
    objectFit: 'cover',
    width: '100%',
    transform: 'scale(2)',
  },
  variants: {
    isActive: {
      true: {
        borderRadius: '100%',
        width: '50px',
        height: '50px',
      },
    },
  },
})

const CharmImg = styled('img', {
  backgroundColor: '$gray300',
  height: '100%',
  objectFit: 'cover',
  width: '100%',
  transform: 'scale(1.5)',
})

const CharmsList = styled('div', {
  display: 'flex',
  margin: '0 -40px',
  padding: '0 40px 10px',
  overflowX: 'scroll',
  '@lg': {
    padding: 0,
    margin: -10,
    flexWrap: 'wrap',
    overflowX: 'visible',
  },
})

const Charms = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'pointer',
})

const CharmTitle = styled('p', {
  projectFont: 'body04',
  textAlign: 'center',
})

const CharmPrice = styled('p', {
  projectFont: 'body05',
  textAlign: 'center',
  color: '$red300',
  fontWeight: 'medium',
})

const CharmLetter = styled('p', {
  projectFont: 'body05',
  textAlign: 'center',
})

const LoadingWrapper = styled('div', {
  maxWidth: '100px',
  margin: '0 auto',
})

const CharmItem = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '5px 12px 0 0',
  alignItems: 'center',
  maxWidth: '33%',
  width: '100%',
  '@xxl': {
    maxWidth: '25%',
    margin: '5px 0',
  },
})

const SelectedCharmsWrapper = styled('div', {
  p: {
    projectFont: 'body04',
  },
})

const SelectedCharms = styled('div', {
  display: 'flex',
  marginBottom: '35px',
  backgroundColor: 'rgba(0,0,0,0.06)',
  padding: '10px',
  borderRadius: '5px',
  justifyContent: 'space-around',
})

const CharmCount = styled('span', {
  projectFont: 'body05',
  display: 'inline-block',
  width: '80vw',
  textAlign: 'right',
  position: 'absolute',
  marginTop: -25,
  '@lg': {
    position: 'relative',
    marginBottom: 15,
    width: '100%',
  },
})

const useCharmData = (
  charms: SectionProductConversionAreaProps['charms'],
  locale: string | undefined,
) => {
  const [products, setProducts] = useState<any[]>([])

  useEffect(() => {
    if (!charms) return
    const getCharms = async () => {
      const tempCharms = []

      for (let p = 0; p < charms?.length; p++) {
        const item = charms[p]

        if (!item || !item?.handle) return false

        const theItem = await getShopifyProductExtended(
          item?.handle,
          locale ?? 'en',
        )

        if (theItem.status === 'ERROR') {
          const exception = new Error(
            `[[...paths]] Sanity getSiteConfiguration function error ${JSON.stringify(
              theItem.errors,
            )}`,
          )
          captureException(exception)
          throw exception
        }

        tempCharms.push(theItem.data)
      }

      return setProducts(tempCharms)
    }

    getCharms()
  }, [charms])

  return {
    products,
  }
}

interface CharmsListProps {
  featuredImage: any
  vId: string | null
  title: string
  priceRange?: any
  price?: string
  id: string
  handle: string
  letter?: string
  index?: number
  handleCharms: (
    vId: string | null,
    id: string,
    handle: string,
    priceRange: any,
    index?: number,
    title?: string,
    featuredImage?: string,
  ) => void
}

const CharmProduct: FC<CharmsListProps> = ({
  vId,
  featuredImage,
  title,
  priceRange,
  id,
  handleCharms,
  handle,
  letter,
  index,
}) => {
  return (
    <Charms
      key={id}
      onClick={() =>
        handleCharms(vId, handle, priceRange, title, index, featuredImage)
      }
    >
      <Picture isActive={index !== undefined && index >= 0}>
        <CharmImg
          src={featuredImage?.image?.src}
          alt={featuredImage?.image?.alt || ''}
        />
      </Picture>
      {index === undefined && (
        <>
          <CharmTitle>{title}</CharmTitle>
          <CharmPrice>
            <PriceRange range={priceRange} shouldComparedPrice={true} />
          </CharmPrice>
        </>
      )}
      {!!letter && <CharmLetter>({letter})</CharmLetter>}
    </Charms>
  )
}

interface Props {
  charms: {
    handle: string
    letterPopUp: boolean
  }[]
  onChange: (optionId: string, valueId?: string, attrs?: any) => void
  onSelect: (activeCharms: any[]) => void
  option: AdvancedProductOption
}

export const ProductCharms: FC<Props> = ({
  charms,
  onSelect,
  onChange,
  option,
}) => {
  const { t } = useTranslation('sectionProductConversionArea')
  const { locale } = useRouter()
  const { products } = useCharmData(charms || [], locale)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [popUpOpened, setPopUpOpened] = useState<boolean>(false)
  const [activeCharms, setActiveCharms] = useState<
    {
      id: string
      price: string
      priceRange: any
      handle: string
      letter?: string
      title: string
      featuredImage?: string
    }[]
  >([])

  useEffect(() => {
    onSelect(activeCharms)

    if (activeCharms.length > 0) {
      onChange(option.title, activeCharms.length.toString())
    } else {
      onChange(option.title, undefined)
    }
  }, [activeCharms])

  useEffect(() => {
    if (products.length > 0) {
      setIsLoading(false)
    }
  }, [products])

  const checkCharm = (handle: string): boolean => {
    return activeCharms.some((charms) => charms.handle === handle)
  }

  const getLetter = (index: number): string => {
    const theCharm = activeCharms.find((_, i) => i === index)?.letter

    return theCharm || ''
  }

  const hasPopUp = (handle: string): boolean => {
    const shouldOpenPopUp =
      charms.some((charm) => charm.handle === handle && !!charm.letterPopUp) &&
      activeCharms.some((ac) => ac.handle === handle) &&
      noLetterPicked(handle)

    if (shouldOpenPopUp && !popUpOpened) {
      setPopUpOpened(true)
    }

    return shouldOpenPopUp
  }

  const removeCharm = (index: number) => {
    const tempList = structuredClone(activeCharms)

    tempList.splice(index, 1)

    setActiveCharms(tempList)
  }

  const removeCharmWithoutLetter = (handle: string) => {
    if (checkCharm(handle)) {
      const charmIndex = activeCharms
        .map((ac) => ac.handle === handle && ac.letter === undefined)
        .indexOf(true)

      removeCharm(charmIndex)
    }

    setPopUpOpened(false)
  }

  const handleCharms = (
    id: string | null,
    handle: string,
    priceRange: any,
    title: string,
    index?: number,
    featuredImage?: string,
  ) => {
    if (popUpOpened) return

    if (checkCharm(handle) && index !== undefined && index >= 0) {
      removeCharm(index)

      return
    }

    if (activeCharms.length >= (option.max_charms || 5) || !id) return

    setActiveCharms([
      {
        id,
        handle,
        price: priceRange.maxVariantPrice.amount,
        priceRange,
        title,
        featuredImage,
      },
      ...activeCharms,
    ])

    return
  }

  const noLetterPicked = (handle: string) => {
    return activeCharms.some(
      (charm) => charm.handle === handle && charm.letter == null,
    )
  }

  const handleLetterPick = (handle: string, letter: string) => {
    const addLetter = activeCharms.map((ac) => {
      if (ac.handle === handle && ac.letter === undefined) {
        ac.letter = letter

        setPopUpOpened(false)
      }

      return ac
    })

    setActiveCharms(addLetter)
  }

  if (!products) return null

  return (
    <Root>
      {!isLoading ? (
        <>
          <CharmCount>
            {activeCharms.length}/{option.max_charms}
          </CharmCount>
          {activeCharms.length > 0 && (
            <SelectedCharmsWrapper>
              <p>{t('clickToRemove')}</p>
              <SelectedCharms>
                {activeCharms.map(
                  ({ featuredImage, title, id, handle, priceRange }, index) => (
                    <CharmProduct
                      key={index}
                      index={index}
                      vId={null}
                      id={id}
                      handle={handle}
                      title={title}
                      priceRange={priceRange}
                      featuredImage={featuredImage}
                      handleCharms={
                        hasPopUp(handle) && noLetterPicked(handle)
                          ? (): any => null
                          : handleCharms
                      }
                      letter={getLetter(index)}
                    />
                  ),
                )}
              </SelectedCharms>
            </SelectedCharmsWrapper>
          )}
          <CharmsList>
            {products.map(
              (
                { variants, featuredImage, title, priceRange, id, handle },
                index,
              ) => (
                <CharmItem key={index + 'charm'}>
                  <CharmProduct
                    key={index}
                    vId={variants[0].id}
                    id={id}
                    handle={handle}
                    title={title}
                    priceRange={priceRange}
                    featuredImage={featuredImage}
                    handleCharms={
                      hasPopUp(handle) && noLetterPicked(handle)
                        ? (): any => null
                        : handleCharms
                    }
                  />
                  {hasPopUp(handle) && (
                    <LetterPopup
                      key={index + 'popup'}
                      onDismiss={() => removeCharmWithoutLetter(handle)}
                      onPick={(letter: string) =>
                        handleLetterPick(handle, letter)
                      }
                    />
                  )}
                </CharmItem>
              ),
            )}
          </CharmsList>
        </>
      ) : (
        <LoadingWrapper>
          <LogoLoading />
        </LoadingWrapper>
      )}
    </Root>
  )
}
