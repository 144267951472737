import { getLocaleRegionIdFromPath } from '@magal/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import {
  AdvancedTemplate,
  PageBuilderSection,
  PageResponse,
  SectionShopifyId,
  ServiceResponse,
} from '@magal/models'
import { createPageBuilderProjection } from './projections/createPageBuilderProjection'
import { LocaleId } from '@magal/configs'
import { createRichTextProjection } from './projections/createRichTextProjection'
import { coalesceLocaleField } from './helpers/coalesceLocaleField'
import { createBlockContentProjection } from './projections/createBlockContentProjection'
import {
  createMediaProjection,
  sanityImageProjection,
} from './projections/createMediaProjection'
import { createEditorialBlockContentProjection } from './projections/createEditorialBlockContentProjection'
import { captureException, withScope } from '@sentry/nextjs'

const COLOR_PROJECTION = groq`{
  hex,
  alpha,
  hsl{h, s, l, a}
}`

export const createProductPageProjection = (localeId: LocaleId) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  const EDITORIAL_BLOCK_CONTENT_PROJECTION =
    createEditorialBlockContentProjection(localeId)
  const BLOCK_CONTENT_PROJECTION = createBlockContentProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
    "showFooter": true,
    "productId": store.gid,
    "pageBuilder": [
      {
        ${coalesceLocaleField(
          'additionalDescription',
          localeId,
        )}[]${RICH_TEXT_PROJECTION},
        ...*[ !(_id in path('drafts.**')) && _type == "productsSettings" ][0] {
          ${coalesceLocaleField('productDescriptionLabel', localeId)},
          enableMaterials,
          ${coalesceLocaleField('shippingShortInfo', localeId)},
          ${coalesceLocaleField(
            'shippingModalContent',
            localeId,
          )}[]${BLOCK_CONTENT_PROJECTION},
          enableTransparentPricing,
          ${coalesceLocaleField('transparentPricingLabel', localeId)},
          transparentPricingMultiplier,
          enableProductCare,
          ${coalesceLocaleField('productCareLabel', localeId)},
          ${coalesceLocaleField(
            'productCare',
            localeId,
          )}[]${BLOCK_CONTENT_PROJECTION},
          enableShippingReturns,
          ${coalesceLocaleField('shippingReturnsLabel', localeId)},
          ${coalesceLocaleField(
            'shippingReturns',
            localeId,
          )}[]${BLOCK_CONTENT_PROJECTION},
          reassurance[]{
            title,
            icon${MEDIA_PROJECTION}
          }
        },
        "sizeGuide": *[_type == "sizeGuide" && productType == ^.store.productType] {
          productType,
          ${coalesceLocaleField('title', localeId)},
          ${coalesceLocaleField(
            'description',
            localeId,
          )}[]${EDITORIAL_BLOCK_CONTENT_PROJECTION},
        }[0],
        "packaging": *[_type == "productPackaging"][0] {
          ${coalesceLocaleField('title', localeId)},
          ${coalesceLocaleField(
            'description',
            localeId,
          )}[]${BLOCK_CONTENT_PROJECTION},
          "enabled": enabled,
          "icon": {
            "mediaPayload": {
              "type": "sanity-image",
              "image": ${sanityImageProjection('icon')}
            }
          },
          values{
            title,
            _type,
            _key,
            "variantId": productVariant -> store.gid,
            ${coalesceLocaleField(
              'description',
              localeId,
            )}[]${BLOCK_CONTENT_PROJECTION},
            "image": {
              "mediaPayload": {
                "type": "sanity-image",
                "image": ${sanityImageProjection('image')}
              }
            }
          }[]
        },
        "productMaterials": *[_type == "productMaterial"] {
          shopifyId,
          ${coalesceLocaleField('label', localeId)},
          ${coalesceLocaleField(
            'description',
            localeId,
          )}[]${BLOCK_CONTENT_PROJECTION},
          image${MEDIA_PROJECTION},
        },
        "productTiles": *[_type == "productTiles"] {
          enabled,
          shopifyId,
          borderRadius,
          borderColor${COLOR_PROJECTION},
          bgColor${COLOR_PROJECTION},
          textColor${COLOR_PROJECTION},
          ${coalesceLocaleField('text', localeId)},
          icon${MEDIA_PROJECTION},
        },
        "_type": "sectionProductConversionArea",
        "_key": "sectionProductConversionArea",
        "productId": store.gid,
        "shopifyIds": [
          {
            "id": store.gid,
            "type": "productExtended"
          },
          ...*[_type=="productPackaging"][0]{
            "values": values[@._type == 'value-variant']{
              _type == 'value-variant' => {
                "id": productVariant -> store.gid,
                "type": "variantBasic"
              }
            }
          }.values
        ],
      },
      {
        "_type": "sectionOkendoReviews",
        "_key": "sectionProductConversionArea",
        "productId": store.gid
      },
      ...pageBuilder[]${PAGE_BUILDER_PROJECTION},
      ...*[ !(_id in path('drafts.**')) && _type == "productsSettings" ][0] {
        pageBuilder[]${PAGE_BUILDER_PROJECTION},
      }.pageBuilder
    ],
  }`
}

export const getProductPageByHandle = async (
  handle: string,
  localeRegionString: string,
  preview = false,
  advancedTemplateHandle: string,
): Promise<ServiceResponse<PageResponse<PageBuilderSection[]>>> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const PRODUCT_PROJECTION = createProductPageProjection(localeId)

  const query = groq`*[!(_id in path('drafts.**')) && _type == "product" && defined(store.slug.current) && store.slug.current == "${handle}" && store.isDeleted == false && store.status == "active"][0]${PRODUCT_PROJECTION}`
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  const advancedTemplateQuery = groq`*[_type == "productAdvancedTemplate" && shopifyReference == "${advancedTemplateHandle}"][0]{
    ...,
    options{
      ...,
      values[]{
        ...,
        "optionId": ^.title,
        _type == 'value-color' => {
          ...,
          color${COLOR_PROJECTION}
        },
        _type == 'value-image' => {
          ...,
          "image": {
            "mediaPayload": {
              "type": "sanity-image",
              "image": ${sanityImageProjection('image')}
            }
          }
        }
      },
      _type == 'additional-variant' => {
        ...,
        values[]{
          ...,
          "variantId": productVariant-> store.gid,
          "optionId": ^.title
        }
      },
      _type == 'upload-file' => {
        ...,
        image${MEDIA_PROJECTION},
        "modalContent": {
          "mediaPayload": {
            "type": "sanity-image",
            "image": ${sanityImageProjection('image')}
          }
        }
      },
      _type == 'charms' => {
        ...,
        "charmItems": charmItems[]{
          "handle": Product->store.slug.current,
          "letterPopUp": enableLetters,
        },
      }
    }[]
  }`

  const pageData: PageResponse<PageBuilderSection[]> = await getClient(preview)
    .fetch(query)
    .catch((e) => {
      withScope(function (scope) {
        scope.setTransactionName('getProductPageByHandle')
        captureException(e)
      })
      return { status: 'ERROR', errors: [e] }
    })

  if (!pageData) {
    return { status: 'OK', data: null }
  }

  if (pageData.pageBuilder[0]._type === 'sectionProductConversionArea') {
    if (advancedTemplateHandle) {
      const advancedTemplate: AdvancedTemplate | undefined = await getClient(
        preview,
      ).fetch(advancedTemplateQuery)

      if (advancedTemplate) {
        pageData.pageBuilder[0].advancedTemplate = advancedTemplate

        const additionalVariantIds = advancedTemplate.options.reduce<
          SectionShopifyId[]
        >((acc, option) => {
          if (option._type === 'additional-variant') {
            const ids = option.values.reduce<SectionShopifyId[]>(
              (idsAcc, val) => {
                if (val._type === 'value-variant' && val.variantId) {
                  return [
                    ...idsAcc,
                    {
                      type: 'variantBasic',
                      id: val.variantId,
                    },
                  ]
                } else {
                  return idsAcc
                }
              },
              [],
            )
            return [...acc, ...ids]
          }
          return acc
        }, [])
        if (additionalVariantIds.length) {
          pageData.pageBuilder[0].shopifyIds = [
            ...pageData.pageBuilder[0].shopifyIds,
            ...additionalVariantIds,
          ]
        }
      }
    }
  }

  return { status: 'OK', data: pageData }
}
